<template>
  <div class="download">
      <div class="download-btn-content">
          <img class="logo" src="https://cdn.9kd.com/kdnet/cattle_page/catttle-bg-download-922.png" />
            <div class="download-btn" id="downloadButton" @click="openInstall()">
            <div class="finger_box">
                <img src="http://cdn.9kd.com/kdnet/kd_download_finger.png" alt="" class="finger" />
            </div>
        <!-- <div class="img-box">
            <img class="img-btn" src="https://cdn.9kd.com/kdnet/cattle_page/catttle-bg-download-btn.png" alt="" />
            <div class="line">

            </div>
        </div> -->
        </div>
      </div>


    <!-- <div class="download-add-animation-1">
        <img src="https://cdn.9kd.com/kdnet/cattle_page/catttle-bg-download-200.png" alt="">
    </div>
    <div class="download-add-animation-2">
        <img src="https://cdn.9kd.com/kdnet/cattle_page/catttle-bg-download-200.png" alt="">
    </div>
    <div class="download-add-animation-3">
        <img src="https://cdn.9kd.com/kdnet/cattle_page/catttle-bg-download-200.png" alt="">
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      androidUrl: '',
      urlParams: {}
    }
  },
  created(){
    this.downloadApp()
  },
  mounted() {
    let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if(!isIOS){
      this.ID = location.origin.indexOf('m.9kd') >= 0 ? "usr14aebgbcm31q3" : "usr1j3n8aekrb0bh";
    }else{
      this.ID = location.origin.indexOf('m.9kd') >= 0 ? 'usr14aebgbcm31q3' : 'usr1j3n8aekrb0bh';
    }

    // url问号后面的参数
    this.urlParams = ULink.getUriDecodeParams()

    // 微信二次分享
    this.queryWxConfigs();
  },
  methods: {
    async downloadApp() {
      let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (!isIOS) {
        let { data: res } = await this.$api.getUpdateInfo();
        if(res.code == 200){
            this.androidUrl = res.data.url;
        }
        this.openInstall()
      }else {
        this.openInstall()
      }
    },
    openInstall(){
        let self = this;
        ULink([
            {
                id: this.ID,          // 后台生成的裂变活动LinkID
                data: this.urlParams,           // 传递的自定义动态参数
                selector: "#downloadButton",    // 按钮的名称
                useOpenInBrowerTips: "default",
                setTimeout: 2000,
                proxyOpenDownload: function (defaultAction, LinkInstance){
                    if (LinkInstance.solution.type === "scheme") {
                        // qq或者微信环境特殊处理下
                        if (ULink.isWechat || ULink.isQQ) {
                            // 在qq或者微信环境执行内置逻辑，具体内置逻辑为:当设置了useOpenInBrowerTips字段时，qq&&微信&&scheme时，启用蒙层提示去浏览器打开
                            defaultAction();
                        }else{
                            window.location.href = self.androidUrl || LinkInstance.solution.downloadUrl;
                        }
                    }else if(LinkInstance.solution.type === "universalLink"){
                        // universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟已于6月2日上线universalLink生成及重定向功能。
                    }
                },
            },
        ]);
    },
    async queryWxConfigs() {
        let shareData = {};
        let result = await this.$api.getWxGameInfo(this.urlParams.iCode)
        let resData = result.data
        if(resData.code == 200){
          shareData = resData.data;
        }
        document.title = shareData.title ? shareData.title : '凯迪网';

        this.setWeChatShare({
          url: window.location.href,
          title: shareData.title ? shareData.title : '【好友福利】快帮我一起养牛，免费领牛肉吧',
          description: shareData.description ? shareData.description : '',
          friendImg: this.$baseInfo.LOGO_IMG,
          momentsImg: this.$baseInfo.MOMENTS_LOGO_IMG
        });
    },
  },
}
</script>

<style lang="less">
.download {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #e1d8c2;
  text-align: center;
  font-size: 15px;
  .download-btn-content{
      position: relative;
  }
  .logo {
    display: block;
    width: 100%;
    height: 100%;
  }
  .download-btn {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 88%;
    height: 15%;
    transform: translate(-50%, 0);
    .img-btn {
      display: block;
      width: 100%;
    }
    .img-box{
      position: relative;
      .line{
        position: absolute;
        top: 14px;
        left: -8%;
        width: 40px;
        height: 10px;
        background: linear-gradient(90deg, rgba(255,255,255,0.0) 0%,rgba(255,235,122,0.8) 30%, rgba(255,235,122,0.8) 70%, rgba(255,255,255,0.0) 100%);
        border-radius: 20px;
        opacity: 0;
        transform: rotate(-60deg);
        animation: leftToright linear 1s infinite;
      }
    }
    .finger_box {
      position: absolute;
      width: 62px;
      animation: fingerAnimation 0.4s ease-in-out infinite alternate;
      .finger {
        width: 100%;
        display: block;
      }
    }
  }
  .download-add-animation-1,.download-add-animation-2,.download-add-animation-3{
      position: absolute;
      right: 20%;
      top: 43%;
      width: 20%;
      opacity: 0;
      animation: toTop 1s infinite;
      img{
        display: block;
        width: 100%;
      }
  }
  .download-add-animation-2{
    animation-delay: .5s;
  }
  .download-add-animation-3{
    animation-delay: 1s;
  }
}
</style>
<style>
@keyframes topLeftToBottomRight {
  from {
    top: 33%;
    right: 19%;
  }
  to {
    top: 54%;
    right: 15%;
  }
}

@keyframes toTop {
  0% {
    top: 43%;
    opacity: 0;
  }
  20%,80% {
    opacity: 1;
  }
  100% {
    top: 38%;
    opacity: 0;
  }
}
@keyframes leftToright{
  0% {
    left: -8%;
    opacity: 0;
  }
  30%,70% {
    opacity: 1;
  }
  100% {
    left: 80%;
    opacity: 0;
  }
}
@keyframes fingerAnimation {
  from {
    top: 18%;
    right: 21%;
  }
  to {
    top: 40%;
    right: 18%;
  }
}

</style>
