<template>
    <div class="welfare-center">
        <div class="welfare-center-bg">
            <img class="bg-img" src="https://cdn.9kd.com/kdnet/wCenter/welfare-center-bg.png" alt="">
            <div class="look-btn" @click="toYSH()">
                <img src="https://cdn.9kd.com/kdnet/wCenter/welfare-center-btn.png" alt="">
            </div>
        </div>
        <div class="welfare-center-content">

            <div class="title">
                每日瓜分金币
            </div>

            <div class="num-content">
                <img class="text-bg" src="https://cdn.9kd.com/kdnet/wCenter/welfare-center-smallbg.png" alt="">
                <div class="text-box">
                    <div class="text-day">
                        <p>昨天</p>
                        <p class="today">今天</p>
                        <p>明天</p>
                    </div>
                    <div class="text-money">
                        <div class="line left"></div>
                        <div class="all-money">金币池 <span>10</span> 万</div>
                        <div class="line right"></div>
                    </div>
                    <div class="text-change">
                        <p class="m-r-4">仅剩</p>
                        <div class="noney-item" v-for="(item,index) in wanData" :key="item+index">{{item}}</div>
                        <p class="m-r-4" v-if="wanData.length">万</p>
                        <div :class="['noney-item',]" ref="moneyItem" v-for="(item,ind) in noWanData" :key="ind+'info2-'">{{item}}</div>
                        <p>名额</p>
                    </div>
                    <div class="sign-btn" @click="toKaidi()">签到领金币</div>
                </div>
            </div>
            <div class="welfare-center-adv">
                <img src="https://cdn.9kd.com/kdnet/wCenter/welfare-center-adv.png" alt="">
                <div class="user-box">
                    <div class="welfare-center-user" ref="userList" :class="{anim:animate==true}" >
                        <p v-for='item in userData' :key="item">{{item}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="day-content">
            <div v-for="(item,index) in dayData" :key="index" class="day-item">
                <div class="day-item-gold">
                    <span :class="[index == 0 ? 'first': '']">{{item.number}}</span>
                    <img src="https://cdn.9kd.com/kdnet/wCenter/welfare-center-jbbg.png" alt="">
                </div>
                <div class="day-item-day">{{item.day}}</div>
            </div>
        </div>
        <div class="no-wait-content">
            <img src="https://cdn.9kd.com/kdnet/wCenter/welfare-center-nowait.png" alt="">
        </div>
        <!-- 微信/QQ内打开外部浏览器引导弹窗 -->
        <van-overlay :show="showGuidePage" z-index="99999" @click="hideGuide()">
            <div class="foot-guide">
                <span class="step step-1">1 点击右上角</span>
                <span class="step step-2">2 选择在浏览器中打开</span>
                <img class="img-arrow" src="https://cdn.9kd.com/kdnet/d92f5b8a79cc484396cbb6fafcbac9fe.png" />
            </div>
        </van-overlay>
    </div>
</template>
<script>
import Bus from '../../utils/bus'
export default {
    data() {
        return {
            userData: [
                '花花阳台已领取200金币',
                '迷茫de月光已领取1000金币',
                '飞剑影落已领取600金币',
                '袋袋不说话已领取800金币',
                '怡然自悦已领取200金币',
                '瞭望天空幻想已领取1000金币',
                '彩笔小王子已领取1000金币',
                '守桥小卒已领取1000金币',
                '只爱布兰妮已领取800金币',
                '鹿角小飞兔已领取800金币',
                '孤独的落叶已领取1000金币',
                '黄鸟立于山下已领取600金币',
                '苏堤烟雨已领取1000金币',
                '我爱我家已领取800金币',
                '夜晚潜水木符已领取1000金币',
                'wentola已领取1000金币',
                '腹中有墨已领取300金币',
                '诺曼普莱斯已领取600金币',
                '正太脸蜀黍心已领取1000金币',
                '我就看看不说已领取400金币',
                '雨中用飘柔已领取800金币',
                '武圣麒麟已领取1000金币',
                '白天的梦想家已领取200金币',
                '回来我的天已领取1000金币',
                '酷酷小虾已领取800金币',
                '绝代天骄已领取500金币',
                '袋袋不说话已领取1000金币'
            ],
            money: 20000,
            moneyData: [1,0,0,0,0,0],
            wanData:[],
            noWanData:[],
            moneyInterval: null,
            currentUser: '',
            userInterval: null,
            animate: false,
            dayData:[
                {number:200, day:'已领'},
                {number:300, day:'明天'},
                {number:400, day:'3天'},
                {number:500, day:'4天'},
                {number:600, day:'5天'},
                {number:800, day:'6天'},
                {number:1000, day:'7天'},
            ],
            showGuidePage: false,
            timer:null,
            isShowBrowser: false,
            isHideBrowser: false,
            downTimer: null
        }
    },
    beforeDestroy() {
        clearInterval(this.moneyInterval);
        clearInterval(this.userInterval);
    },
    mounted() {
        let moneyStr = this.money + ''
        this.wanData = moneyStr.slice(0,moneyStr.length - 4);
        this.noWanData = moneyStr.slice(moneyStr.length - 4);
        this.moneyInterval = setInterval(() => {
            this.money -= 1;
            let mStr = this.money + ''
            if(this.money >= 10000) {
                this.wanData = mStr.slice(0,mStr.length - 4);
                this.noWanData = mStr.slice(mStr.length - 4);
            }else {
                this.wanData = []
                this.noWanData = mStr.slice("");
            }
        }, 3000);

        this.currentUser = this.userData[0];
        this.userInterval = setInterval(this.userScroll,6000)
    },
    methods: {
        toKaidi() {
            let isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if(!isIos){
                if (navigator.userAgent.indexOf('MicroMessenger') >= 0 || navigator.userAgent.match(/\sQQ/i) == ' qq') {
                    this.showGuidePage = true;
                    this.timer = setTimeout(() => {
                      this.showGuidePage = false;
                    }, 3000)
                } else {
                    this.downloadApp()
                }
            } else {
                this.downloadApp()
            }

        },
        hideGuide() {
            this.showGuidePage = false;
            clearTimeout(this.timer);
        },
        userScroll() {
            let userList = this.$refs.userList;
            userList.style.marginTop = '-28px';
            this.animate = !this.animate;
            setTimeout(()=>{
                this.userData.push(this.userData[0]);
                this.userData.shift();
                userList.style.marginTop = '0px';
                this.animate = !this.animate;
            },800)
        },
        downloadApp() {
            window._hmt = window._hmt || [];
            window._hmt.push(['_trackEvent', '福利中心打开或下载APP', '点击', '福利中心-签到领金币']) // 事件跟踪
            this.clearTimer();
            let u = navigator.userAgent;
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (!isiOS) {
                this.isHideBrowser = false;
                window.location.href = 'kdapp://main:7700';
                this.checkAppDialogOpen();
                this.downTimer = setTimeout( () => {
                    if (this.isHideBrowser === false && this.isShowBrowser === false) {
                        //未安装app
                        window.location.href = 'https://kdnet-prod-app-package.oss-cn-shanghai.aliyuncs.com/kdnet_' + 'backup02' + '_4.3.0_build_206.apk'
                        this.$api.countQRCodeDownload()
                    }
                }, 2000);

            } else {
                Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true });
            }
        },
        checkAppDialogOpen() {
            // 检查 app 是否打开
            let self = this;
            function checkOpen(){
                var _clickTime = +(new Date());
                function check(elsTime) {
                    if ( elsTime > 3000) {
                        self.isShowBrowser = true;
                    } else {
                        self.isShowBrowser = false;
                    }
                }
                // 启动间隔 20ms 运行的定时器，并检测累计消耗时间是否超过 3000ms，超过则结束
                var _count = 0, intHandle;
                intHandle = setInterval(function(){
                    _count++;
                    var elsTime = +(new Date()) - _clickTime;
                    if (_count >= 100 || elsTime > 3000 ) {
                        clearInterval(intHandle);
                        check(elsTime);
                    }
                }, 25);
            }
            checkOpen();
        },
        //清除定时器
        clearTimer() {
            if (this.downTimer) {
                clearTimeout(this.downTimer)
            }
        },
        toYSH(){
            window._hmt.push(['_trackEvent', '福利中心打开云商会下载页', '点击', '福利中心-助农立即查看']) // 事件跟踪
            window.location.href = 'https://wxaurl.cn/LcxlCXOQx3b'
        }
    }
}
</script>
<style lang="less" scoped>
    .welfare-center{
        min-height: 100vh;
        background: #B70216;
        font-size: 14px;
        .m-r-4{
            margin-right: 4px;
        }
    }
    .welfare-center-bg{
        position: relative;
        width: 100%;
        .bg-img{
            display: block;
            width: 100%;
        }
        .look-btn{
            position: absolute;
            left: 50%;
            top: 63%;
            width: 72px;
            transform: translate(-50%, 0);
            img{
                display: block;
                width: 100%;
            }
        }
    }
    .welfare-center-content{
        position: relative;
        padding: 10px 16px 20px 16px;
        margin: -20px 10px 0 10px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(210,202,202,0.30);
        z-index: 2;
        .title{
            font-size: 16px;
            font-weight: 500;
            font-weight: bold;
            color: #222222;
        }
        .welfare-center-adv{
            display: flex;
            align-items: center;
            margin-top: 12px;
            font-size: 12px;
            color: #C5C5C5;
            img{
                display: block;
                width: 12px;
                height: 12px;
                margin-right: 5px;
            }
            .user-box{
                width: 300px;
                height: 30px;
                line-height: 30px;
                overflow: hidden;
                // transition: all 0.5s;
            }
            .anim{
                transition: all 0.5s;
            }
        }
        .num-content{
            position: relative;
            margin-top: 12px;
            border-radius: 10px;
            background: #fff9f3;
            overflow: hidden;
            .text-bg{
                display: block;
                width: 100%;
            }
            .text-box{
                position: absolute;
                top: 0;
                width: 100%;
                .text-day{
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    height: 45px;
                    line-height: 45px;
                    color: #C5C5C5;
                    font-size: 16px;
                    .today{
                        color: #F7321C;
                        font-weight: bold;
                    }
                }
                .text-money{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 5px;
                    .line{
                        width: 34px;
                        height: 2px;
                        margin-top: 10px;
                        &.left{
                            background: linear-gradient(to left ,rgba(247,50,28,1),rgba(247,50,28,0));
                        }
                        &.right{
                            background: linear-gradient(to right ,rgba(247,50,28,1),rgba(247,50,28,0));
                        }
                    }
                    .all-money{
                        margin: 0 10px;
                        color: #F7321C;
                        font-size: 20px;
                        font-weight: bold;
                        span{
                            font-size: 34px;
                        }
                    }
                }
                .text-change{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 15px;
                    color: #C5C5C5;
                    font-size: 16px;
                    .noney-item{
                        width: 28px;
                        height: 40px;
                        background: linear-gradient(180deg,#ff5820 0%, #ff8f78);
                        border-radius: 2px;
                        box-shadow: 0px 0px 3px 0px #df7958 inset;
                        color: white;
                        margin-right: 4px;
                        font-size: 30px;
                        text-align: center;
                        line-height: 40px;
                        font-weight: bold;
                        transition: all 1s;
                    }
                }
                .sign-btn{
                    width: 235px;
                    height: 40px;
                    margin: 20px auto 0 auto;
                    background: linear-gradient(180deg,#fd917b, #f7321c 99%);
                    border-radius: 20px;
                    text-align: center;
                    line-height: 40px;
                    color: white;
                    animation: scaleAni 0.5s infinite alternate;
                }
            }
        }
    }
    .day-content{
        display: flex;
        justify-content: space-between;
        padding: 12px 13px;
        margin: 12px 10px;
        border-radius: 10px;
        background: white;
        .day-item{
            width: 12%;
            .day-item-gold{
                padding: 7px;
                border-radius: 6px;
                background: #FFF5D2;
                text-align: center;
                color: #FF6900;
                font-size: 10px;
                span{
                    display: block;
                }
                .first{
                    color: #999999;
                }
                img{
                    display: block;
                    width: 95%;
                    margin: auto;
                }
            }
            .day-item-day{
                margin-top: 8px;
                font-size: 11px;
                color: #999999;
                text-align: center;
            }
        }
    }
    .no-wait-content{
        padding: 8px 0 0 18px;
        img{
            width: 90%;
        }
    }
</style>
<style>
@keyframes scaleAni {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}
</style>
